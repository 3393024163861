<template>
  <div class="main-view" >
    <div class="section shadow filter__wrap" style="flex:1;">
      <el-form
        v-loading="loading"
        element-loading-text="加载中…"
        ref="elFormDom"
        label-width="130px"
        label-position="right"
        :model="form.data"
        :rules="form.rules"
      >
        <el-form-item prop="desc" label="标题">
          <el-input
            v-model="form.data.desc"
            clearable
            placeholder="接单必看"
          ></el-input>
        </el-form-item>
        <el-form-item prop="content" label="内容">
          <my-editor ref="editor" :editorTxt="this.form.data.content"></my-editor>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import MyEditor from "@/components/MyEditor.vue";
import { mixinTable } from '@/mixins/table.js';

export default {
  name: 'clauseName',
  mixins: [mixinTable],
  components: {
    MyEditor
  },
  data() {
    return {
      loading: false,
      form: {
        data: {
          id: '',
          key: 'order', // rule:平台规则 order:接单必看
          desc: '',
          content: '',
        },
        rules: {
          desc: [{required: true, message: "请输入标题！", trigger: "change"}],
          // content: [{required: true, message: "必填项", trigger: "change" }],
        }
      },
      params: {
        page: 1,
        count: 10
      },
    }
  },
  mounted() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/flex_article/list', { params:this.params }).then(res => {
        if(res.code === 1) {
          if(res.data.list.length > 0) {
            res.data.list.forEach(v => {
              if(v.key == 'order') {
                this.form.data.id = v.id;
                this.form.data.desc = v.desc;
                this.form.data.content = v.content;
                // this.$refs.editor.editorContent = v.content;
              }
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    submitForm() {
      this.$refs.elFormDom.validate((valid) => {
        if(valid) {
          this.form.data.content = this.$refs.editor.editorContent;
          if (!this.form.data.content) {
            this.$message.warning("正文不能为空");
            return;
          }
          let apiUrl = !!this.form.data.id ? '/admin/flex_article/edit' : '/admin/flex_article/add'
          this.$http.post(apiUrl, this.form.data).then(res => {
            if(res.code == 1) {
              this.$message.success('保存成功！')
            }
          })
        }
      })
    },
    resetForm() {
      this.$refs.elFormDom.resetFields();
    }
    
  }
}
</script>